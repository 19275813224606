<template>
  <b-overlay
      :show="overlay"
      rounded="sm"
  >
  <div v-if="category !== null">
    <b-card>

      <b-form>
        <b-row>

          <b-col
              cols="12"
              class="mb-1"
          >
            <h3>Category title</h3>
          </b-col>

          <!-- Field: name -->
          <b-col
              cols="12"
              md="4"
          >
            <b-form-group
                label-for="name"
            >
              <b-form-input
                  id="name"
                  v-model="category.name"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>

      <!-- Action Buttons -->
      <b-button
          variant="primary"
          class="mt-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="updateCategory"
      >
        Save changes
      </b-button>

    </b-card>

  </div>
  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BFormGroup, BForm, BModal, BOverlay,
} from 'bootstrap-vue'
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {BlogCategoryUpdateRequest} from "@/libs/Api/Blog";
import {BlogCategoryGetRequest} from "@/libs/Api/Blog";


export default {
  name: "categories-edit",
  title:"Edit Blog Categories",
  data(){
    return{
      category:null,
      categoryProperties:null,
      overlay:false,
      categoryId:this.$route.params.id,
      addNewCategoryProperty:false,
      myTableColumns : [
        { key: 'categoryPropertyId',label:'شناسه'},
        { key: 'name',label:'نام'},
        { key: 'delete',label:'حذف'},
        { key: 'edit',label:'ویرایش'},
      ],
      defaultCreateCategoryPropertyData:{
        isDeleted: false,
        categoryPropertyId: 0,
        categoryId: this.$route.params.id,
        name: ""
      },
      updateCategoryPropertyData:null,
    }
  },
  async created(){
    await this.getCategory();
  },
  methods:{
    async getCategory(){
      let _this = this;
      _this.overlay = true;

      let blogCategoryGetRequest = new BlogCategoryGetRequest(_this);
      blogCategoryGetRequest.setId(_this.categoryId);
      await blogCategoryGetRequest.fetch(function (content){
        _this.overlay = false;
        _this.category = content;
      },function (error){
        _this.overlay = false;
        console.log(error)
      })
    },
    async updateCategory(){
      let _this = this;
      _this.overlay = true;

      let blogCategoryUpdateRequest = new BlogCategoryUpdateRequest(_this);
      blogCategoryUpdateRequest.setParams(_this.category);
      await blogCategoryUpdateRequest.fetch(function (content){
        _this.overlay = false;
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `Done`,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        _this.getCategory()
      },function (error){
        _this.overlay = false;
        console.log(error)
      })
    },
  },
  components:{
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BFormGroup,
    BBadge,
    BForm,
    BDropdown,
    BDropdownItem,
    BPagination,
    BModal,

    vSelect,
  }
}
</script>

<style scoped>

</style>